import CardApplicationSuccessful from 'components/Card/CardApplicationSuccessful';
import ConnectPaycheck, { PaycheckFeedback } from 'components/ConnectPaycheck';
import ReferralProgram from 'components/ReferralProgram';
import {
  CardNextResult,
  ConnectPaycheckResult,
  ApplyEmployeeLoanResult,
  CardSummaryResult,
} from 'enums/FlowNextResults';
import { RoutePath } from 'enums/Routes';
import ImportBalance from 'components/CardFlow/ImportBalance';
import BuildCreditAvoidDebtSaveMoney from 'components/CardFlow/BuildCreditAvoidDebtSaveMoney';
import CardRewards from 'components/CardFlow/CardRewards';
import SmartSpending from 'components/CardFlow/SmartSpending';
import SmartAutoPay from 'components/CardFlow/SmartAutoPay';
import ImportDebt from 'components/CardFlow/ImportDebt';
import Summary from 'components/CardFlow/Summary';
import ApplyEmployeeLoan from 'components/CardFlow/ApplyEmployeeLoan';
import StayInTouch from 'components/CardFlow/StayInTouch';

import { NextFunction, RouterType } from './types';

export const getCardRoutes = (showReferralImmediatelyAfterApply: boolean, next: NextFunction): RouterType => ({
  [RoutePath.ImportBalance]: {
    navigationInfo: { showBackLink: false, title: 'Healthcare Employee Lending' },
    component: ImportBalance,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourSavings);
    },
  },
  [RoutePath.CardApplicationSuccessful]: {
    navigationInfo: { title: 'Next Steps' },
    component: CardApplicationSuccessful,
    handleNext: (args) => () => {
      const { navigate } = args;
      if (showReferralImmediatelyAfterApply) {
        navigate(RoutePath.BuildCreditAvoidDebtSaveMoney);
      } else {
        next(args)(CardNextResult.FromCardApplied);
      }
    },
  },
  [RoutePath.BuildCreditAvoidDebtSaveMoney]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: BuildCreditAvoidDebtSaveMoney,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.CardRewards);
    },
  },
  [RoutePath.CardRewards]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: CardRewards,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.SmartSpending);
    },
  },
  [RoutePath.SmartSpending]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: SmartSpending,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.SmartAutoPay);
    },
  },
  [RoutePath.SmartAutoPay]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: SmartAutoPay,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ImportDebt);
    },
  },
  [RoutePath.ImportDebt]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: ImportDebt,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.CardSummary);
    },
  },
  [RoutePath.CardSummary]: {
    navigationInfo: { showBackLink: true, title: 'Card Benefits' },
    component: Summary,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case CardSummaryResult.NotNow:
          navigate(RoutePath.Feedback);
          break;
        default:
          navigate(RoutePath.Analyzing);
          break;
      }
    },
  },
  [RoutePath.ReferralProgram]: {
    navigationInfo: { showBackLink: false, title: 'Plannery Card' },
    component: ReferralProgram,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ApplyEmployeeLoan, {
        state: { isCardJoinedWaitlist: true },
      });
    },
  },
  [RoutePath.ApplyEmployeeLoan]: {
    navigationInfo: { showBackLink: true, title: 'Plannery Card' },
    component: ApplyEmployeeLoan,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case ApplyEmployeeLoanResult.Apply:
          navigate(RoutePath.ConfirmYourDetails);
          break;
        case ApplyEmployeeLoanResult.ThanksForJoining:
          navigate(RoutePath.CardApplicationSuccessful);
          break;
        default:
          navigate(RoutePath.StayInTouch);
          break;
      }
    },
  },
  [RoutePath.ConnectPaycheck]: {
    component: ConnectPaycheck,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case ConnectPaycheckResult.Next:
          navigate(RoutePath.ReferralProgram, {
            state: {
              fromPaycheckSwitch: true,
            },
          });
          break;
        case ConnectPaycheckResult.NotNow:
          navigate(RoutePath.ConnectPaycheckFeedback);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.ConnectPaycheckFeedback]: {
    component: PaycheckFeedback,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ReferralProgram);
    },
  },
  [RoutePath.StayInTouch]: {
    component: StayInTouch,
    navigationInfo: { title: 'Next Steps' },
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ReferralProgram);
    },
  },
});
