import React from 'react';

import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';

import Modal from 'components/Common/Modal';
import { FlowNextResult } from 'enums/FlowNextResults';

import { ReactComponent as OpenQuotesIcon } from 'images/open-quotes-icon.svg';
import { ReactComponent as CloseQuotesIcon } from 'images/close-quotes-icon.svg';
import nurseJahiraImage from 'images/nurse-jahira.png';
import nurseJasonImage from 'images/nurse-jason.png';

import styles from './AreYouSureModal.module.scss';

export enum TestimonialName {
  Jason = 'Jason',
  Jahira = 'Jahira',
}

interface TestimonialProps {
  name: string;
  image: string;
  testimonial: string;
  credentials: string;
}

interface HealthcareTestimonialProps {
  [TestimonialName.Jason]: TestimonialProps;
  [TestimonialName.Jahira]: TestimonialProps;
}

interface AreYouSureModalProps {
  closeModal: () => void;
  handleDecline: (result?: FlowNextResult) => void;
  testimonialName?: TestimonialName;
}

const healthcareDataTestimonial: HealthcareTestimonialProps = {
  [TestimonialName.Jason]: {
    name: TestimonialName.Jason,
    image: nurseJasonImage,
    testimonial:
      "Plannery has been life changing. I'm so thankful to have a rising credit score, and a plan to be debt free in 3 years.",
    credentials: 'PA-C',
  },
  [TestimonialName.Jahira]: {
    name: TestimonialName.Jahira,
    image: nurseJahiraImage,
    testimonial:
      'The savings have been life-changing, and because of this financial relief, I feel much more secure and less stressed about my finances.',
    credentials: 'BSN, RN',
  },
};

const AreYouSureModal = ({ handleDecline, closeModal, testimonialName }: AreYouSureModalProps) => {
  const testimonialData = healthcareDataTestimonial[testimonialName || TestimonialName.Jason];

  return (
    <Modal closeModal={closeModal}>
      <>
        <p className={styles.title}>Are You Sure?</p>
        <div className={styles.mainContainer}>
          <p className={styles.subtitle}>
            Do your part and help people like {testimonialData.name} find out about Plannery!
          </p>

          <div className={styles.card}>
            <div className={styles.overlayImageContainer}>
              <div className={styles.outerImageContainer}>
                <div className={styles.imageContainer}>
                  <img
                    className={styles.image}
                    src={testimonialData.image}
                    alt={`${testimonialData.credentials} - ${testimonialData.name}`}
                  />
                </div>
              </div>
            </div>
            <div className={styles.testimonialContainer}>
              <OpenQuotesIcon className={styles.openQuotesIcon} />
              <p className={styles.testimonial}>{testimonialData.testimonial}</p>
              <div className={styles.personalInfoContainer}>
                <p className={styles.name}>{testimonialData.name}</p>
                <p className={styles.credentials}>{testimonialData.credentials}</p>
                <CloseQuotesIcon className={styles.closeQuotesIcon} />
              </div>
            </div>
          </div>

          <div className={styles.buttonsContainer}>
            <Button onClick={closeModal} className={styles.button}>
              Share Plannery
            </Button>
            <Button onClick={handleDecline} type={ButtonType.Inverted}>
              No Thanks
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default AreYouSureModal;
