import YourEmail from 'components/FinancialCheckup/Email/YourEmail';
import VerifyEmail from 'components/VerifyEmail';
import YourIncome from 'components/FinancialCheckup/Income/YourIncome';
import MethodAuth from 'components/FinancialCheckup/MethodAuth';
import BirthDate from 'components/LoanForm/BirthDate';
import YourAddress from 'components/LoanForm/YourAddress';
import YourSSN4 from 'components/LoanForm/YourSSN/YourSSN';
import VerifyPhoneNumber from 'components/VerifyPhoneNumber';
import { RoutePath } from 'enums/Routes';
import YourPhoneNumber from 'components/FinancialCheckup/PhoneNumber/YourPhoneNumber';
import { VerifyEmailResult, YourPhoneNumberResult } from 'enums/FlowNextResults';

import { CustomRouterType, NextFunction, RouterType } from './types';

export const getApplyStageRoutes = (
  yourNameRouter: CustomRouterType,
  analyzingComponent: CustomRouterType['component'],
  next: NextFunction,
): RouterType => ({
  [RoutePath.YourName]: yourNameRouter,
  [RoutePath.YourPhoneNumber]: {
    navigationInfo: { showBackLink: true, title: 'Your Phone Number', step: 2, stepCount: 5 },
    component: YourPhoneNumber,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case YourPhoneNumberResult.Exit:
          navigate(RoutePath.ExitSurvey);
          break;
        default:
          navigate(RoutePath.VerifyPhoneNumber);
          break;
      }
    },
  },
  [RoutePath.VerifyPhoneNumber]: {
    navigationInfo: { showBackLink: true, title: 'Verify Phone Number', step: 3, stepCount: 5 },
    component: VerifyPhoneNumber,
    handleNext: ({ navigate }) => async () => {
      navigate(RoutePath.YourEmail);
    },
  },
  [RoutePath.YourEmail]: {
    navigationInfo: { showBackLink: true, title: 'Your Email', step: 4, stepCount: 5 },
    component: YourEmail,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerifyEmail);
    },
  },
  [RoutePath.VerifyEmail]: {
    navigationInfo: { showBackLink: true, title: 'Verify Email', step: 4, stepCount: 5 },
    component: VerifyEmail,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case VerifyEmailResult.CardFlow:
          navigate(RoutePath.BuildCreditAvoidDebtSaveMoney);
          break;
        default:
          navigate(RoutePath.YourIncome);
          break;
      }
    },
  },
  [RoutePath.YourIncome]: {
    navigationInfo: { showBackLink: true, title: 'Your Income', step: 5, stepCount: 5 },
    component: YourIncome,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Analyzing);
    },
  },
  [RoutePath.MethodAuth]: {
    navigationInfo: { showBackLink: true, title: 'Connect', step: 4, stepCount: 4 },
    component: MethodAuth,
    handleNext: () => () => {},
  },

  [RoutePath.YourBirthDate]: {
    navigationInfo: { title: 'Apply' },
    component: BirthDate,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourSSN4);
    },
  },
  [RoutePath.YourSSN4]: {
    navigationInfo: { title: 'Apply' },
    component: YourSSN4,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourAddress);
    },
  },
  [RoutePath.YourAddress]: {
    navigationInfo: { title: 'Apply' },
    component: YourAddress,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.Analyzing);
    },
  },
  [RoutePath.Analyzing]: {
    component: analyzingComponent,
    handleNext: next,
  },
});
