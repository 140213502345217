import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button, { ButtonType } from 'components/Button/Button';
import Stepper from 'components/Card/Stepper/Stepper';
import FormNavigation from 'components/FormNavigation';
import FormContainer from 'components/LoanForm/FormContainer';
import AutoPayBenefitsList from 'components/Card/BenefitsList';
import { FlowComponentType } from 'routes/types';
import { getCardData } from 'selectors/getCardData';
import { CurrentOnboardingSteps, setCurrentOnboardingStep } from 'handlers/cardData';

import { ReactComponent as Paycheck } from 'images/fast-bill.svg';
import { ReactComponent as PlanneryLogo } from 'images/plannery-logo.svg';
import { ReactComponent as BankIcon } from 'images/purple-bank-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-rounded-icon.svg';
import { ReactComponent as PlusIcon } from 'images/plus-rounded-icon.svg';

import { autoPayBenefitsList } from './benefitsList';

import styles from './SmartAutoPay.module.scss';

const SmartAutoPay = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { currentOnboardingStep } = useSelector(getCardData);

  useEffect(() => {
    if (currentOnboardingStep !== CurrentOnboardingSteps.SmartAutoPay)
      dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.SmartAutoPay));
  }, []);

  const handleNextStep = async (enabled = false) => {
    // TODO: Store in user application
    analytics.track('Smart Auto Pay Selected', { enabled });
    dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.ImportDebt));
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <Stepper currentStep={currentOnboardingStep} />
      <FormContainer
        title={
          <>
            <span className={styles.highLightTitle}>Smart Auto Pay:</span> The Best Way to Build Credit with Plannery
          </>
        }
        subtitle="Connect directly to your paycheck to help you stay out of debt."
      >
        <div className={styles.containerPaycheckFlow}>
          <div className={styles.paycheckFlowCard}>
            <Paycheck />
            <p className={styles.payCheckFlowCardName}>Your paycheck.</p>
            <ArrowRightIcon className={styles.arrow} />
          </div>

          <div className={styles.paycheckFlowCard}>
            <PlanneryLogo />
            <p className={styles.payCheckFlowCardName}>Your card payment.</p>
            <PlusIcon className={styles.arrow} />
          </div>

          <div className={styles.paycheckFlowCard}>
            <BankIcon />
            <p className={styles.payCheckFlowCardName}>
              <span>Remainder</span>
              <br />
              to your bank.
            </p>
          </div>
        </div>
        <AutoPayBenefitsList benefits={autoPayBenefitsList} />
        <p className={styles.preferenceText}>
          <span>Save Your Smart Auto Pay Preference</span>²
        </p>
        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={() => handleNextStep(true)}>
            Opt In When Available
          </Button>
          <Button type={ButtonType.Transparent} onClick={handleNext}>
            Opt Out
          </Button>
        </div>
      </FormContainer>
      <div className={styles.disclaimersContainer}>
        <p>¹ Smart Auto Pay incentives are subject to change.</p>
        <p>² Your preference does not guarantee feature availability or approval for Plannery Card.</p>
      </div>
    </>
  );
};

export default SmartAutoPay;
