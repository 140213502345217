import React, { useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import TextArea from 'components/TextArea';
import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';

import useCurrentFlow from 'hooks/useCurrentFlow';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { UtmSource } from 'enums/UtmTagName';
import { FeedbackResult } from 'enums/FlowNextResults';
import { FlowComponentType } from 'routes/types';
import { useNavigate } from 'hooks/useNavigate';
import { updateApplicationFeedbackData } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import { RootState } from 'handlers';

import styles from './Feedback.module.scss';

enum Fields {
  Experience = 'experience',
  HowDidYouHear = 'how_did_you_hear',
}

enum FieldTypes {
  WaitList = 'waitList',
  NormalFlow = 'normalFlow',
}

interface TextAreaField {
  label: string;
  name: Fields;
  placeholder: string;
  type: FieldTypes;
  showField?: (utmSource?: string) => boolean;
}

const textareaFields: TextAreaField[] = [
  {
    label: 'Why don’t you want to sign up for the waitlist?',
    name: Fields.Experience,
    placeholder: 'Tell us anything on your mind.',
    type: FieldTypes.WaitList,
  },
  {
    label: 'What could we change that would convince you to join?',
    name: Fields.HowDidYouHear,
    placeholder: 'Any details are welcome.',
    type: FieldTypes.WaitList,
  },
  {
    label: 'How was your experience?',
    placeholder: 'Tell us anything on your mind.',
    name: Fields.Experience,
    type: FieldTypes.NormalFlow,
  },
  {
    label: 'How did you hear about us?',
    placeholder: 'Any details are welcome.',
    name: Fields.HowDidYouHear,
    type: FieldTypes.NormalFlow,
    showField: (utmSource?: string) => !utmSource || utmSource === UtmSource.Site,
  },
];

const Feedback = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const navigate = useNavigate();
  const { isCardFlow } = useCurrentFlow();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { application } = useSelector(getApplicationData);
  const utmSource = application?.utmSource;
  const experience = useSelector((state: RootState) => state.applicationData.application?.feedbackExperience);
  const howDidYouHear = useSelector((state: RootState) => state.applicationData.application?.feedbackHowDidYouHear);
  const [isLoading, setIsLoading] = useState(false);

  const { register, setValue, watch } = useForm({
    defaultValues: {
      [Fields.Experience]: experience,
      [Fields.HowDidYouHear]: howDidYouHear,
    },
  });

  const watcher = watch();

  useEffect(() => {
    register(Fields.Experience);
    register(Fields.HowDidYouHear);
  }, [register, watcher]);

  const handleFieldOnChange = useCallback((event, fieldName: Fields) => {
    setValue(fieldName, event.target.value);
  }, []);

  const handleContinue = useCallback(async () => {
    setIsLoading(true);
    if (watcher[Fields.Experience] || watcher[Fields.HowDidYouHear]) {
      if (isCardFlow) {
        analytics.track('Card Feedback Submitted', {
          waitListOptOutReason: watcher[Fields.Experience],
          waitListImprovementSuggestion: watcher[Fields.HowDidYouHear],
        });
      }
      await dispatchWithUnwrap(
        updateApplicationFeedbackData({
          applicationId: application!.id,
          experience: watcher[Fields.Experience],
          howDidYouHear: watcher[Fields.HowDidYouHear],
        }),
      );
    }
    setIsLoading(false);
    handleNext(isCardFlow ? FeedbackResult.CrossSell : FeedbackResult.NextSteps);
  }, [dispatchWithUnwrap, navigate, watcher, application]);

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="We’d love your feedback.">
        {textareaFields.map((field) => {
          const isFieldHidden =
            (field.type === FieldTypes.NormalFlow && isCardFlow) ||
            (field.type === FieldTypes.WaitList && !isCardFlow) ||
            (field.showField && !field.showField(utmSource));

          if (isFieldHidden) return null;

          return (
            <TextArea
              key={field.name}
              label={field.label}
              placeholder={field.placeholder}
              className={styles.formInput}
              name={field.name}
              onChange={(event) => handleFieldOnChange(event, field.name)}
              value={watcher[field.name]}
            />
          );
        })}

        <Button onClick={handleContinue} isLoading={isLoading}>
          Next
        </Button>
      </FormContainer>
    </>
  );
};

export default Feedback;
