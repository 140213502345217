import React from 'react';
import Button, { ButtonType } from 'components/Button';

interface BackToWebsiteProps {
  text?: string;
  onClick?: () => void;
  className?: string;
}

const BackToWebsite = ({ text = 'Back to Plannery Website', onClick, className }: BackToWebsiteProps) => {
  const handleGetBack = () => {
    window.open('https://www.planneryapp.com/platform/healthcare-professionals');
  };

  return (
    <Button className={className} type={ButtonType.Secondary} onClick={onClick || handleGetBack}>
      {text}
    </Button>
  );
};

export default BackToWebsite;
