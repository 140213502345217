import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import FormNavigation from 'components/FormNavigation';
import Stepper from 'components/Card/Stepper/Stepper';
import { FlowComponentType } from 'routes/types';
import Button, { ButtonType } from 'components/Button/Button';
import FormContainer from 'components/LoanForm/FormContainer';
import { CurrentOnboardingSteps, setCurrentOnboardingStep } from 'handlers/cardData';
import { getCardData } from 'selectors/getCardData';

import styles from './SmartSpending.module.scss';

interface CardProps {
  title: string;
  description: string;
}
interface SpendCategoriesProps {
  monthlyBudget: CardProps[];
  whenNeeded: CardProps[];
}

const spendCategories: SpendCategoriesProps = {
  monthlyBudget: [
    {
      title: 'Essentials',
      description: 'Groceries, gas.',
    },
    {
      title: 'Work',
      description: 'Scrubs, shoes, tools.',
    },
    {
      title: 'Wellness',
      description: 'Dining out, fitness.',
    },
    {
      title: 'Travel',
      description: 'Time off, vacations.',
    },
    {
      title: 'Shopping',
      description: 'Clothes, home goods.',
    },
  ],
  whenNeeded: [
    {
      title: 'Emergencies',
      description: 'Unexpected costs.',
    },
  ],
};

const SmartSpending = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { monthlyBudget, whenNeeded } = spendCategories;
  const { currentOnboardingStep } = useSelector(getCardData);

  useEffect(() => {
    if (currentOnboardingStep !== CurrentOnboardingSteps.SmartSpending)
      dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.SmartSpending));
  }, []);

  const handleNextStep = (enabled = false) => {
    // TODO: Store in user application
    analytics.track('Smart Spending Limits Selected', { enabled });
    dispatch(setCurrentOnboardingStep(CurrentOnboardingSteps.SmartAutoPay));
    handleNext();
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <Stepper currentStep={currentOnboardingStep} />
      <FormContainer
        title="Set Smart Spending Limits."
        subtitle="It’s like you have six cards in one, each handling a spend category:"
      >
        <div>
          <div className={styles.monthlyBudgetContainer}>
            <span>Monthly Budget</span>
            <div className={styles.spendCategories}>
              {monthlyBudget.map((category, index) => (
                <div className={styles.spendCategory} key={index}>
                  <span>{category.title}</span>
                  {category.description}
                </div>
              ))}
            </div>
          </div>
          <div className={clsx(styles.monthlyBudgetContainer, styles.whenNeededContainer)}>
            <span>When Needed</span>
            {whenNeeded.map((category, index) => (
              <div className={styles.spendCategory} key={index}>
                <span>{category.title}</span>
                {category.description}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.limitsContainer}>
          <span>You Set the Limits</span>
          Change limits as needed and stay in budget with 24/7 help from an AI coach.
        </div>

        <span className={styles.preferenceText}>Save Your Smart Spending Limits Preference</span>

        <div className={styles.buttonsContainer}>
          <Button type={ButtonType.Primary} onClick={() => handleNextStep(true)}>
            OPT in when available
          </Button>
          <Button type={ButtonType.Inverted} onClick={handleNextStep}>
            OPT out
          </Button>
        </div>
      </FormContainer>
      <div className={styles.disclaimerContainer}>
        Your preference does not guarantee feature availability or approval for Plannery Card.
      </div>
    </>
  );
};

export default SmartSpending;
