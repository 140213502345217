import React from 'react';

import FormContainer from 'components/LoanForm/FormContainer';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { authOtp, sendEmailCode } from 'thunks';
import { useSelector } from 'react-redux';
import { getAuthData } from 'selectors/getAuthData';
import { getYourContactData } from 'selectors/getYourContact';
import { VerifyEmailResult } from 'enums/FlowNextResults';
import VerifyCode from 'components/VerifyCode';
import { ContinueMode } from 'components/VerifyCode/VerifyCode';

const VerifyEmail = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isCardFlow } = useCurrentFlow();

  const { emailId } = useSelector(getAuthData);
  const { phone_number: phoneNumber, email } = useSelector(getYourContactData);

  const onResendCode = async () => {
    await dispatchWithUnwrap(sendEmailCode({ phoneNumber, email }));
  };

  const onVerify = async (code: string) => {
    const authResponse = await dispatchWithUnwrap(authOtp({ code, phoneOrEmailId: emailId! }));
    if (authResponse.verified) {
      handleNext(isCardFlow ? VerifyEmailResult.CardFlow : undefined);
    }
    return authResponse.verified;
  };

  // TODO: Refactor: isCardFlow

  return (
    <>
      <FormNavigation
        {...navigationInfo}
        {...{
          step: isCardFlow ? 5 : navigationInfo.step,
          title: isCardFlow ? 'Sign Up' : navigationInfo.title,
        }}
      />
      <FormContainer title="Verify Email" subtitle={`Enter the 6-digit authorization code sent to ${email}`}>
        <VerifyCode
          onResendCode={onResendCode}
          onVerify={onVerify}
          continueMode={ContinueMode.OnCodeValid}
          noAutoComplete
        />
      </FormContainer>
    </>
  );
};

export default VerifyEmail;
