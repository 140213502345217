import { RoutePath } from 'enums/Routes';
import PayOffPlan from 'components/HardOffer/components/PayOffPlan';
import {
  ConfirmLoanResult,
  ConfirmYourDetailsResult,
  EmploymentDetailsResult,
  FeedbackResult,
  HowItWorksResult,
  PayOffResult,
  StepsResult,
  YourSavingsResult,
} from 'enums/FlowNextResults';
import SetupAccount from 'components/Verification/VerificationHome';
import { VerifyStatus } from 'api/LoanOfferApi';
import ConfirmLoan from 'components/ConfirmLoan';
import DocuSign from 'components/DocuSign';
import EmailNotificationAlert from 'components/EmailNotificationAlert';
import Feedback from 'components/Feedback';
import FundsSent from 'components/FundsSent';
import PlanSent from 'components/HardOffer/components/PlanSent';
import NextSteps from 'components/NextSteps';
import OfferStatus from 'components/OfferStatus';
import RepaymentMethod from 'components/RepaymentMethod';
import SetupDeduction from 'components/SetupDeduction';
import { ManualDeductionMethod } from 'components/SetupDeduction/ManualDeduction/ManualDeduction';
import SetupViaHr from 'components/SetupDeduction/ManualDeduction/components/SetupViaHr';
import SetupViaPayroll from 'components/SetupDeduction/ManualDeduction/components/SetupViaPayroll';
import VerifyPayrollDeduction from 'components/SetupDeduction/ManualDeduction/components/VerifyPayrollDeduction';
import Alloy from 'components/Verification/UploadLicense';
import { VerificationNavigation } from 'components/Verification/verificationSteps';
import VerifyingApplication from 'components/VerifyingApplication/VerifyingApplication';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { updateApplicationStatus } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import OfferAvailable from 'components/DebtConsolidation/OfferAvailable';
import HowItWorks from 'components/FinancialCheckup/HowItWorks';
import YourSavings from 'components/FinancialCheckup/YourSavings';
import ConfirmYourDetails from 'components/LoanForm/ConfirmYourDetails';

import { NextFunction, RouterType } from './types';

export const EMPLOYEE_LENDING_TITLE = 'Your Offer';
export const VERIFICATION_TITLE = 'Verification';
export const SETUP_DEDUCTION_TITLE = 'Setup Deduction';

export const getDebtConsolidationRoutes = (next: NextFunction): RouterType => ({
  [RoutePath.OfferAvailable]: {
    navigationInfo: { showBackLink: false, title: EMPLOYEE_LENDING_TITLE },
    component: OfferAvailable,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.YourSavings);
    },
  },
  [RoutePath.YourSavings]: {
    navigationInfo: { showBackLink: true, title: EMPLOYEE_LENDING_TITLE },
    component: YourSavings,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case YourSavingsResult.Continue:
          navigate(RoutePath.HowItWorks);
          break;
        case YourSavingsResult.ContinueNoSavings:
          navigate(RoutePath.PayOffPlan);
          break;
        case YourSavingsResult.BackToYourFinances:
          navigate(RoutePath.YourFinances);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.HowItWorks]: {
    navigationInfo: { showBackLink: true, title: EMPLOYEE_LENDING_TITLE },
    component: HowItWorks,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case HowItWorksResult.Continue:
          navigate(RoutePath.PayOffPlan);
          break;
        case HowItWorksResult.BackToYourFinances:
          navigate(RoutePath.YourFinances);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.PayOffPlan]: {
    navigationInfo: { title: EMPLOYEE_LENDING_TITLE },
    component: PayOffPlan,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case PayOffResult.Continue:
          navigate(RoutePath.ConfirmLoan);
          break;
        case PayOffResult.EmailSent:
          navigate(RoutePath.PlanSent);
          break;
        case PayOffResult.BackToYourFinances:
          next(args)(undefined);
          break;
        case PayOffResult.ManualReview:
          navigate(RoutePath.OfferStatus);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.PlanSent]: {
    component: PlanSent,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.ConfirmLoan);
    },
  },
  [RoutePath.ConfirmLoan]: {
    navigationInfo: { showBackLink: true, title: EMPLOYEE_LENDING_TITLE },
    component: ConfirmLoan,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case ConfirmLoanResult.Continue:
          navigate(RoutePath.SetupAccount);
          break;
        case ConfirmLoanResult.BackToYourFinances:
          next(args)(undefined);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.SetupAccount]: {
    component: SetupAccount,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerificationSteps);
    },
  },
  [RoutePath.VerificationSteps]: {
    component: VerificationNavigation,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case StepsResult.Completed:
          navigate(RoutePath.VerifyingApplication);
          break;
        case EmploymentDetailsResult.Continue:
          break;
        case EmploymentDetailsResult.Rejected:
        case StepsResult.Rejected:
          navigate(RoutePath.OfferStatus);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.VerifyingApplication]: {
    navigationInfo: { showBackLink: false, title: VERIFICATION_TITLE },
    component: VerifyingApplication,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case VerifyStatus.PendingDocuments:
          navigate(RoutePath.Alloy);
          break;
        case VerifyStatus.Verified:
          navigate(RoutePath.RepaymentMethod);
          break;
        case VerifyStatus.ManualVerify:
        case VerifyStatus.Rejected:
          navigate(RoutePath.OfferStatus);
          break;
        default:
          navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.Alloy]: {
    navigationInfo: { showBackLink: false, title: VERIFICATION_TITLE },
    component: Alloy,
    handleNext: ({ navigate }) => async () => {
      navigate(RoutePath.VerifyingApplication);
    },
  },
  [RoutePath.RepaymentMethod]: {
    navigationInfo: { showBackLink: false, title: SETUP_DEDUCTION_TITLE, step: 1, stepCount: 3 },
    component: RepaymentMethod,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.SetupDeduction);
    },
  },
  [RoutePath.SetupDeduction]: {
    navigationInfo: { showBackLink: true, title: SETUP_DEDUCTION_TITLE, step: 2, stepCount: 3 },
    component: SetupDeduction,
    handleNext: ({ state, dispatchWithUnwrap, navigate }) => async () => {
      const { application } = getApplicationData(state!);
      await dispatchWithUnwrap(
        updateApplicationStatus({
          applicationId: application!.id,
          applicationStatus: ApplicationStatusName.FinalReview,
        }),
      );
      navigate(RoutePath.OfferStatus);
    },
  },
  [RoutePath.SetupViaPayroll]: {
    navigationInfo: { showBackLink: true, title: SETUP_DEDUCTION_TITLE },
    component: SetupViaPayroll,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.VerifyPayrollDeduction, { state: { flow: ManualDeductionMethod.ViaPayroll } });
    },
  },
  [RoutePath.SetupViaHr]: {
    component: SetupViaHr,
  },
  [RoutePath.VerifyPayrollDeduction]: {
    navigationInfo: { showBackLink: true, title: SETUP_DEDUCTION_TITLE },
    component: VerifyPayrollDeduction,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.OfferStatus);
    },
  },
  [RoutePath.DocuSignSignature]: {
    component: DocuSign,
  },
  [RoutePath.EmailNotificationAlert]: {
    // Can we remove this?
    component: EmailNotificationAlert,
  },
  [RoutePath.NextSteps]: {
    component: NextSteps,
    handleNext: next,
  },
  [RoutePath.OfferStatus]: {
    component: OfferStatus,
    handleNext: next,
  },
  [RoutePath.Feedback]: {
    component: Feedback,
    navigationInfo: { showBackLink: true, title: 'Feedback' },
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case FeedbackResult.NextSteps:
          navigate(RoutePath.NextSteps);
          break;
        default:
          navigate(RoutePath.ApplyEmployeeLoan);
          break;
      }
    },
  },
  [RoutePath.FundsSent]: {
    component: FundsSent,
  },
  [RoutePath.ConfirmYourDetails]: {
    navigationInfo: { showBackLink: true, title: 'Apply', step: 1, stepCount: 2 },
    component: ConfirmYourDetails,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case ConfirmYourDetailsResult.UpdateFields:
          navigate(RoutePath.YourName, {
            state: { isFromConfirmDetails: true },
          });
          break;
        default:
          navigate(RoutePath.YourIncome, {
            state: {
              fromCardFlow: true,
            },
          });
          break;
      }
    },
  },
});
