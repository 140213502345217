import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import FormNavigation from 'components/FormNavigation';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { YourNameInputLabel } from 'components/LoanForm/YourName/YourName';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';

import { getFullName, setFirstAndLastName } from 'handlers/yourName';
import { getYourNameData } from 'selectors/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { getMessageForRequiredFields } from 'utils/errors';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { getCardData } from 'selectors/getCardData';
import { CardDataVariable, setCardData } from 'handlers/cardData';
import { FlowComponentType } from 'routes/types';
import { useQueryParams } from 'hooks/useQueryParam';
import { setSelectedTheme } from 'handlers/theme';
import useScreenSize from 'hooks/useScreenSize';
import InputSelect from 'components/InputSelect';
import { InputSelectOption } from 'components/InputSelect/InputSelect';
import { YourNameResult } from 'enums/FlowNextResults';
import { LocalStorageKeyName } from 'enums/LocalStorageKeyName';
import { getNewInputValue } from 'utils/inputHelpers';
import { getProfessionGroup } from 'selectors/professionGroup';
import { ProfessionGroup } from 'enums/ProfessionGroup';
import { setProfessionGroup } from 'handlers/professionGroup';
import { UrlParams } from 'enums/UrlParams';

import CardModel, { Color } from './CardModel/CardModel';
import { CardColor, DEFAULT_COLOR_SET, cards, colorSets } from './cardVersions';

import styles from './Customize.module.scss';

const CREDENTIALS_CHOICES: Readonly<Record<ProfessionGroup, Readonly<string[]>>> = {
  [ProfessionGroup.Nurse]: ['RN', 'LPN', 'BSN, RN', 'MSN, RN', 'APRN', 'ARNP', 'FNP'],
  [ProfessionGroup.Physician]: ['MD', 'DO'],
  [ProfessionGroup.Healthcare]: ['CNA', 'PA-C', 'RN', 'LPN', 'MD', ''],
} as const;

export const COLOR_PARAM_TO_CARD_COLOR: Record<string, CardColor> = {
  Black_Gold: CardColor.Gold,
  DarkPurple_Silver: CardColor.PurpleSilver,
  Purple_White: CardColor.LightPurple,
} as const;

const PROFESSION_GROUPS: Record<string, ProfessionGroup> = {
  RN: ProfessionGroup.Nurse,
  LPN: ProfessionGroup.Nurse,
  BSN: ProfessionGroup.Nurse,
  'BSN, RN': ProfessionGroup.Nurse,
  'MSN, RN': ProfessionGroup.Nurse,
  APRN: ProfessionGroup.Nurse,
  ARNP: ProfessionGroup.Nurse,
  FNP: ProfessionGroup.Nurse,
  MD: ProfessionGroup.Physician,
  DO: ProfessionGroup.Physician,
  CNA: ProfessionGroup.Healthcare,
  'PA-C': ProfessionGroup.Healthcare,
} as const;

const Customize = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const state = useLocation().state as { isSessionExpired?: boolean };
  const isSessionExpired = state?.isSessionExpired;

  const colorSet = colorSets[params.get(UrlParams.CardVersion) as keyof typeof colorSets] || DEFAULT_COLOR_SET;

  const dispatch = useDispatch();

  const { cardColor } = useSelector(getCardData);
  const [selectedColor, setSelectedColor] = useState<CardColor>(cardColor ?? colorSet.defaultColor);
  const { theme, setTheme } = useLayoutTheme();
  const { isMobile } = useScreenSize();

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { borrowerCredentials } = useSelector(getCardData);

  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
      credentials: borrowerCredentials ?? '',
    },
  });

  const watcher = watch();

  const shouldAddComma = (watcher.first_name || watcher.last_name) && watcher.credentials;
  const cardText = `${watcher.first_name} ${watcher.last_name}${shouldAddComma ? ',' : ''} ${watcher.credentials}`;

  const onCardClick = (card: CardColor) => {
    setSelectedColor(card);
    setTheme(cards[card].theme);
    dispatch(setSelectedTheme(cards[card].theme));
    analytics.track('Card Color Selected', { color: selectedColor });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(
      event.target.name as AboutYouVariable,
      getNewInputValue(watcher[event.target.name as AboutYouVariable], event.target.value),
    );
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(
      event.target.name as AboutYouVariable,
      getNewInputValue(watcher[event.target.name as AboutYouVariable], event.target.value),
    );
    trigger(event.target.name as AboutYouVariable);
  };

  const onSelectChange = (option: InputSelectOption) => {
    let { value } = option;
    if (option.value === 'Other') {
      value = '';
      setSelectedOther(true);
    }
    setValue(CardDataVariable.Credentials, value);
    trigger(CardDataVariable.Credentials);
  };

  const onNext = () => {
    dispatch(setFirstAndLastName(watcher));
    dispatch(setCardData({ cardColor: selectedColor, borrowerCredentials: watcher.credentials }));
    dispatch(setProfessionGroup(newGroup));

    analytics.identify({
      cardColor: selectedColor,
      professionGroup: newGroup,
      credentials: watcher.credentials,
      firstName: watcher.first_name,
      lastName: watcher.last_name,
      name: getFullName(watcher.first_name, watcher.last_name),
    });

    localStorage.setItem(LocalStorageKeyName.Theme, cards[selectedColor].theme);
    handleNext();
  };

  const credentialsChoices = CREDENTIALS_CHOICES[professionGroup];

  const [selectedOther, setSelectedOther] = useState(
    watcher[CardDataVariable.Credentials] && !credentialsChoices.includes(watcher[CardDataVariable.Credentials]),
  );

  const newGroup = PROFESSION_GROUPS[watcher[CardDataVariable.Credentials]] || professionGroup;

  useEffect(() => {
    register(AboutYouVariable.FirstName, { required: getMessageForRequiredFields(YourNameInputLabel.FirstName) });
    register(AboutYouVariable.LastName, { required: getMessageForRequiredFields(YourNameInputLabel.LastName) });
    register(
      CardDataVariable.Credentials,
      professionGroup === ProfessionGroup.Healthcare
        ? {}
        : { required: getMessageForRequiredFields(YourNameInputLabel.Credentials) },
    );
  }, []);

  const selectedCard = cards[selectedColor];

  const displayCardByColor = (color: CardColor, side: string) => {
    const pickedCard = cards[color];

    if (side === 'front') {
      return <pickedCard.front />;
    }
    return <pickedCard.back />;
  };

  useEffect(() => {
    setTheme(selectedCard.theme);
  }, []);

  const cardModelColor = (() => {
    switch (selectedColor) {
      case CardColor.PurpleSilver:
        return Color.DarkPurpleSilver;
      case CardColor.LightPurple:
        return Color.PurpleWhite;
      default:
        return Color.BlackGold;
    }
  })();

  return (
    <div className={styles.container}>
      <FormNavigation
        onBackClick={() => handleNext(YourNameResult.Exit)}
        className={styles.formNavigation}
        {...{ ...navigationInfo, title: !isMobile ? 'Customize Your Card' : navigationInfo.title }}
        titleAsHeading={!isMobile}
      />
      <FormContainer
        className={styles.formContainer}
        title={isMobile ? 'Customize Your Card' : undefined}
        subtitle="Preview and save a personalized card."
      >
        {isSessionExpired && (
          <div className={styles.sessionExpired}>Sorry, your session is expired. Please reapply.</div>
        )}
        <div className={styles.container}>
          <div className={styles.contentWrapper}>
            <div className={styles.leftContent}>
              <div className={clsx(styles.inputs, styles[`inputs__${theme}`])}>
                <Input
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  errorMessage={errors[AboutYouVariable.FirstName]?.message}
                  label="First name"
                  placeholder="First Name"
                  name={AboutYouVariable.FirstName}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={watcher[AboutYouVariable.FirstName]}
                  data-neuro-label="firstName"
                  autoComplete="given-name"
                />
                <Input
                  className={styles.inputContainer}
                  inputClassName={styles.input}
                  errorMessage={errors[AboutYouVariable.LastName]?.message}
                  label="Last name"
                  placeholder="Last Name"
                  name={AboutYouVariable.LastName}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={watcher[AboutYouVariable.LastName]}
                  data-neuro-label="lastName"
                  autoComplete="family-name"
                />

                {!selectedOther && (
                  <InputSelect
                    onChange={onSelectChange}
                    className={styles.inputContainer}
                    placeholder="Select"
                    value={watcher[CardDataVariable.Credentials]}
                    name={CardDataVariable.Credentials}
                    label="Credentials"
                    onBlur={onBlur}
                    options={[...credentialsChoices, 'Other'].map((item) => ({ label: item || 'None', value: item }))}
                  />
                )}

                {selectedOther && (
                  <Input
                    className={styles.inputContainer}
                    inputClassName={styles.input}
                    label="Credentials"
                    placeholder="Credentials"
                    name={CardDataVariable.Credentials}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={watcher[CardDataVariable.Credentials]}
                  />
                )}
              </div>

              <div className={styles.cardPickerContainer}>
                <p className={styles.label}>Select color</p>
                <div className={styles.cardPickerItems}>
                  {Object.values(colorSet.colors).map((color, index) => (
                    <div
                      key={`${color}-${index}`}
                      className={clsx(styles.cardItem, styles[`cardItem__${theme}`])}
                      onClick={() => onCardClick(color)}
                    >
                      <div
                        className={clsx(styles.card, styles[`card__${theme}`], {
                          [styles.selected]: selectedColor === color,
                        })}
                      >
                        {displayCardByColor(color, 'front')}
                      </div>
                      <p className={styles.cardColor}>{cards[color].name}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={styles.previewContainer}>
              <CardModel text={cardText} color={cardModelColor} group={newGroup} />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <div className={styles.disclaimerContainer}>
              By clicking Next, I hereby consent to the{' '}
              <a href="https://www.planneryapp.com/terms-of-service" target="_blank" rel="noreferrer">
                Terms of Service
              </a>{' '}
              and{' '}
              <a href="https://www.planneryapp.com/communication-policy" target="_blank" rel="noreferrer">
                Communication Policy
              </a>
              , the terms and benefits of the product are subject to change, and approval is subject to submitting a
              qualified application when the card launches.
            </div>
            <Button disabled={!isValid} onClick={onNext} className={styles.button}>
              Next
            </Button>
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

export default Customize;
